const Home = (props) => {
    return ( 
        <div className="home">
            <div className="homepage-top">
                <div className="intro">Welcome to</div>
                <div className="heading"><span className="stadi">Stadi</span><span className="verse">verse</span></div>
            </div>
        </div>
     );
}
 
export default Home;