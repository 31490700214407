import { useRef, useMemo } from "react";
import { Canvas, useFrame } from '@react-three/fiber';
import { TextureLoader } from "three";

import WindTexture from "../../../../../img/20230119_17_0p25.png";
import WindTextureJson from "../../../../../img/20230119_17_0p25.json";

const GlobeWindLayer = ()=>{

    const uMin = WindTextureJson.header.decoding.umin;
    const uMax = WindTextureJson.header.decoding.umax;
    const vMin = WindTextureJson.header.decoding.vmin;
    const vMax = WindTextureJson.header.decoding.vmax;
    const div = WindTextureJson.header.decoding.div;

    const data = useMemo(()=>({
        // wireframe:true,
        uniforms: {
            uTime: { value: 0.0 },
            uTexture: { value: new TextureLoader().load(WindTexture) },
            uMin: { value: uMin },
            uMax: { value: uMax },
            vMin: { value: vMin },
            vMax: { value: vMax },
            div: { value: div},
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform float uTime;
            uniform float uMin;
            uniform float uMax;
            uniform float vMin;
            uniform float vMax;
            uniform float div;
            uniform sampler2D uTexture;
            varying vec2 vUv;
            void main() {
                vec4 textureSample = texture2D(uTexture, vUv);
                gl_FragColor = vec4(
                    textureSample.r,
                    textureSample.g,
                    textureSample.b,
                    1
                );
            }
        `,
    }))

    const materialRef = useRef();
    const ref = useRef()
    useFrame(({clock}) => {
        ref.current.rotation.x = ref.current.rotation.y += 0.001
        materialRef.current.uniforms.uTime = {value: clock.getElapsedTime()}
    });

    return(
        <mesh rotation={[0,Math.PI*1/2-0.0025,0]} position={[0,0,0]} ref={ref}>
                <sphereGeometry args={[1.1,20,20]}/>
                <shaderMaterial attach="material" ref={materialRef} {...data}/>
        </mesh>
    )
}

export default GlobeWindLayer