import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ({userObject, setUserObject}) =>{
    const navigate = useNavigate()
    useEffect(()=>{
        sessionStorage.clear();
        setUserObject({});
        navigate('/');
    })    
}
export default Logout