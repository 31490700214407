import { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import Sphere from "./Sphere";

const Globe = () => {
    const [selectedPoint, setSelectedPoint] = useState(undefined);
    const hour = new Date().getUTCHours();
    const month = new Date().getMonth();
    const sunPosition = [5*Math.cos(Math.PI*(hour-5)/12),(month-6)/6,5*Math.sin(Math.PI*(hour-5)/12)];
    return (
        <>
            <Canvas shadows camera={{ fov: 30, near: 0.1, far: 1000, position: [0, 1, 5] }}>
                <Suspense fallback={null}>
                    <ambientLight intensity={0.4} />
                    <directionalLight
                        intensity={1}
                        position={sunPosition}
                        lookAt={[0, 0, 0]}
                        color="#ffffff"
                    />
                    <Sphere selectedPoint={selectedPoint} setSelectedPoint={setSelectedPoint}/>
                </Suspense>
            </Canvas>
            <div style={{ position: 'absolute', top: 40, right: 40, fontSize: '15px', textAlign: 'right', background:"none", color:'var(--sub3)', fontFamily:'DentonXbold'}}>
                {selectedPoint?.city?selectedPoint?.city + ", "+selectedPoint?.country:"-- --"}
            </div>
            <div style={{ position: 'absolute', top: 60, right: 40, fontSize: '15px', textAlign: 'right', background:"none", color:'var(--sub3)', fontFamily:'DentonRegular'}}>
                {selectedPoint?.latitude?"Lat: "+selectedPoint?.latitude.toFixed(4):""}
                <br/>
                {selectedPoint?.longitude?"Lon: "+selectedPoint?.longitude.toFixed(4):""}
            </div>
        </>
    )
}

export default Globe;