import Navbar from './Navbar/Navbar';
import Home from './Pages/Home';
import {useState} from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Footer from './Footer/Footer';
import Sailing from './Pages/Sailing/Sailing';
import SailingInfo from './Pages/Sailing/SailingInfo';
import LoginRegister from './Pages/LoginRegister/LoginRegister';
import Logout from './Pages/LoginRegister/Logout';
import FantasyDraftHelper from './Pages/Football/FantasyDraftHelper';

function App() {
  const [userObject, setUserObject] = useState(JSON.parse(sessionStorage.getItem('user'))?JSON.parse(sessionStorage.getItem('user')):{});

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar userObject={userObject} setUserObject={setUserObject}/>
        <div className="content" style={{minHeight:window.innerHeight-75}}>
          <Routes>
            <Route path="/Login" element={<LoginRegister userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="/Logout" element={<Logout userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="/Sailing" element={<Sailing userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="/FantasyDraftHelper" element={<FantasyDraftHelper userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="/Info/Sailing" element={<SailingInfo userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="/" element={<Home userObject={userObject} setUserObject={setUserObject}/>}/>
            <Route path="*" element={<Home userObject={userObject} setUserObject={setUserObject}/>}/>
          </Routes>
        </div>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;