
const cleaningFunction = (playerList) => {
    const uniquePlayer = new Set();
    let cleanedPlayerList = playerList.filter((player) => {
        if (!uniquePlayer.has(player.Name)) {
          uniquePlayer.add(player.Name);
          return true; // Keep the player
        }
        return false; // Duplicate, filter it out
        })
    // // RULE IMPLIMENTATION
    // RULE: TOP 10 WR should get a bump in value as they usually outperform expectations
    const topWRs = cleanedPlayerList
        .filter((player)=> player.Position === 'WR')
        .sort((a,b)=> b.FPTS - a.FPTS)
        .slice(0,10);
    const updatedWRs = topWRs.map((wr)=> ({
        ...wr, FPTS: wr.FPTS * 1.05,
    }));
    const updatedPlayers = cleanedPlayerList.map((player)=> {
        const updatedWR = updatedWRs.find((wr)=>wr.Name===player.Name);
        return updatedWR ? updatedWR : player
    })
    cleanedPlayerList = updatedPlayers
    // RULE: Rookie WRs and QBs rarely perform at expectations. Downgrade by 25% of projected points
    // RULE: Rookie RB's have the chance to do extremely well if put in a pass catching role
    // RULE: A top QB is essential. Only take a QB within the top 6
    // RULE: Pick kicker last and play the matchups week to week
    // RULE: Pick DST second to last and play the matchups week to week
    // RULE: TE's follow trendlines year to year. Pick an established TE with a proven track record
    // RULE: TE's generally have the best VBD scores as the top 1 or 2 will generally score significantly more points
    // RULE: RB's ADP 11-20 are just as likely to finish in the top 20 as RB's 21-30. Hence a lot of value can be gained by holding off on RBs in the middle rounds of the draft.
    // RULE: Replacement mid-tier WR's are easy to come by.
    // Add position ranks
    const positionRanks = {};
    cleanedPlayerList.forEach((player)=>{
        const {Position, FPTS} = player;
        if (!positionRanks[Position]) {
            positionRanks[Position] = []
        }
        positionRanks[Position].push(FPTS);
    });
    Object.keys(positionRanks).forEach((Position) => {
        positionRanks[Position].sort((a, b) => b - a); // Sort in descending order
        positionRanks[Position].forEach((points, index) => {
            const player = cleanedPlayerList.find((p) => p.Position === Position && p.FPTS === points);
            player.positionRank = index + 1;
        });
    });
    //return finished array
    return cleanedPlayerList;
}

export default cleaningFunction;