import { useEffect, useState } from "react";

const PopupForm = ({ entityCost, item, reason, closePopup, handleSailboatEdit, presets}) => {
    const [BoT,setBoT] = useState(Number(presets.attributes["B"])/2);
    const [Cx,setCx] = useState(Number(presets.attributes["Cx"]));
    const [Cp,setCp] = useState(Number(presets.attributes["Cp"]));
    const [Cv,setCv] = useState(Number(presets.attributes["Cv"]));
    const [MainsailHeightMultiplier, setMainsailHeightMultiplier] = useState((Number(presets.attributes["MainsailHeight"])/(Math.sqrt((Number(presets.attributes["Cp"])*Number(presets.attributes["Cx"])/(Number(presets.attributes["Cv"])/1000))*Number(presets.attributes["B"])/2*(2**2)))-0.6)/0.9);
    const [HullKeelBulb, setHullKeelBulb] = useState(Number(presets.attributes["HullKeelBulb"]));
    const [HullKeelDraftMultiplier, setHullKeelDraftMultiplier] = useState((Number(presets.attributes["HullKeelDraft"])-0.5)/(Number(presets.attributes["B"])/2));
    const [MainsailType, setMainsailType] = useState(Number(presets.attributes["MainsailType"]));
    const [StaysailType, setStaysailType] = useState(Number(presets.attributes["StaysailType"]));
    const [SpinnakerType, setSpinnakerType] = useState(Number(presets.attributes["SpinnakerType"]));
    const [MainsailShapeMultiplier, setMainsailShapeMultiplier] = useState(Number(presets.attributes["MainsailShape"]));

    const [errorNotice,setErrorNotice] = useState(undefined);
    
    const [HullKeelDraft, setHullKeelDraft] = useState(HullKeelDraftMultiplier*BoT+0.5);
    const [Hull_L, setHull_L] = useState(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)));
    const [MainsailHeight, setMainsailHeight] = useState(Hull_L * (0.6 + MainsailHeightMultiplier*0.9));
    const [MainsailBottomWidth, setMainsailBottomWidth] = useState(Hull_L *2/5);
    const [MainsailArea, setMainsailArea] = useState(MainsailHeight*(MainsailBottomWidth+MainsailBottomWidth*MainsailShapeMultiplier)/2);
    
    const KeelBulbOptions = ['Wing','Bulb'];
    const MainsailTypes = ['Normal', 'Adjustable Checkstays'];
    const StaysailTypes = ['Normal', 'Adjustable Forestay'];
    const SpinnakerTypes = ['Symmetrical','Asymetrical','Asymetrical w/ pole']

    useEffect(()=>{
        setHull_L(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)));
        setMainsailHeight(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)) * (0.6 + MainsailHeightMultiplier*0.9));
        setMainsailBottomWidth(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)) *2/5);
        setMainsailArea(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)) * (0.6 + MainsailHeightMultiplier*0.9)*(Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)) *2/5 + Math.sqrt((Cp*Cx/(Cv/1000))*BoT*(2**2)) * 2/5 * MainsailShapeMultiplier)/2);
        setHullKeelDraft(HullKeelDraftMultiplier*BoT+0.5);
    },[MainsailHeightMultiplier, BoT, Cx, Cp, Cv, MainsailShapeMultiplier, HullKeelDraftMultiplier])

    function entityObjectAssembly(e){
        e.preventDefault();
        const formData = new FormData(e.target);
        let errorCheck = false;
        let attributeObject = {};
        let formSubmission = {
            name: Math.random().toString(16).substr(2, 12),
            role: item,
            attributes: attributeObject
        };
        for (const [key, value] of formData) {
            if (key ==="name"){
                if(RegExp("[^A-z|0-9_ ]").test(value)){
                    errorCheck="Entity name may only contain letters, numbers, spaces, or underscores";
                } else if (value){
                    formSubmission["name"]=value;
                }
            } else {
                if (key !=="null"){
                    attributeObject[key]=value;
                }
            }
        }
        if (errorCheck===false){
            handleSailboatEdit(formSubmission, errorCheck);
        }else{
            setErrorNotice(errorCheck);
        }
    }


    return (
        <div className="popupFormContainer">
            <form className="popupForm" onSubmit={entityObjectAssembly}>
                {errorNotice!==undefined && <div className="errorNotice">{errorNotice}</div>}
                <input type="text" name="name" placeholder="Enter Desired Name"></input>
                <div className="popupFormDiv">Beam</div>
                <input onChange={(e)=>{setBoT(e.target.value)}} type="range" step="0.01" min="2.5" max="6" value={BoT} className="slider" name="BoT"></input> {(BoT*2).toFixed(2)} meters
                <div className="popupFormDiv">Cx</div>
                <input onChange={(e)=>{setCx(e.target.value)}} type="range" step="0.01" min="0.65" max="0.93" value={Cx} className="slider" name="Cx"></input>
                <div className="popupFormDiv">Cp</div>
                <input onChange={(e)=>{setCp(e.target.value)}} type="range" step="0.01" min="0.5" max="0.7" value={Cp} className="slider" name="Cp"></input>
                <div className="popupFormDiv">Cv</div>
                <input onChange={(e)=>{setCv(e.target.value)}} type="range" step="0.01" min="3" max="6" value={Cv} className="slider" name="Cv"></input> Hull Length: {Hull_L.toFixed(2)} meters
                <div className="popupFormDiv">Keel Bulb or Wing Type?</div>
                <select className="dropdownSelect" required="required" name="HullKeelBulb" value={HullKeelBulb} onChange={(e)=>{setHullKeelBulb(e.target.value)}}>
                    {KeelBulbOptions.map((entity,index) => {
                        return (<option key={entity} value={index} >{entity} </option>)
                    })}
                </select>
                <div className="popupFormDiv">Keel Draft</div>
                <input onChange={(e)=>{setHullKeelDraftMultiplier(e.target.value)}} type="range" step="0.01" min="0" max="1" value={HullKeelDraftMultiplier} className="slider" name="HullKeelDraft"></input> {HullKeelDraft.toFixed(2)} meters
                <div className="popupFormDiv">Mainsail Type</div>
                <select className="dropdownSelect" required="required" name="MainsailType" value={MainsailType} onChange={(e)=>{setMainsailType(e.target.value)}}>
                    {MainsailTypes.map((entity,index) => {
                        return (<option key={entity} value={index} >{entity} </option>)
                    })}
                </select>
                <div className="popupFormDiv">Staysail Type</div>
                <select className="dropdownSelect" required="required" name="StaysailType" value={StaysailType} onChange={(e)=>{setStaysailType(e.target.value)}}>
                    {StaysailTypes.map((entity,index) => {
                        return (<option key={entity} value={index} >{entity} </option>)
                    })}
                </select>
                <div className="popupFormDiv">Spinnaker Type</div>
                <select className="dropdownSelect" required="required" name="SpinnakerType" value={SpinnakerType} onChange={(e)=>{setSpinnakerType(e.target.value)}}>
                    {SpinnakerTypes.map((entity,index) => {
                        return (<option key={entity} value={index} >{entity} </option>)
                    })}
                </select>
                <div className="popupFormDiv">Mainsail Height</div>
                <input onChange={(e)=>{setMainsailHeightMultiplier(e.target.value)}} type="range" step="0.01" min="0" max="1" value={MainsailHeightMultiplier} className="slider" name="MainsailHeight"></input> {MainsailHeight.toFixed(2)} meters
                <div className="popupFormDiv">Mainsail Area</div>
                <input onChange={(e)=>{setMainsailShapeMultiplier(e.target.value)}} type="range" step="0.01" min="0" max="1" value={MainsailShapeMultiplier} className="slider" name="MainsailShape"></input> {MainsailArea.toFixed(2)} sq. meters
                <input className="submitButton" type="submit" value={reason+" "+item+": "+entityCost}></input>
            </form>
        </div>
    );
}

export default PopupForm;