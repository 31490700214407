import { DropdownItems } from "./DropdownItems";
import MenuItems from "./MenuItems";
const Navbar = ({userObject, setUserObject}) => {
    function refresh(){
    }

    const LoginDropdown = 
    [{
        title:"Login",
        link:"/Login"
    }]
    let LoggedInDropdown = 
    [{
        title: (userObject.hasOwnProperty('username')?(userObject['username'].length>9?userObject['username'].substring(0, 8)+"..":userObject['username']):""),
        submenu: [
            {
                title: "Tokens: " + String(userObject.hasOwnProperty('tokens')?userObject['tokens']:""),
                link: {}
            },
            {
                title: "Logout",
                link: "/Logout"
            }
        ]

    }]
    let ConnectWallet = [{
        title: "Connect Wallet",
        link:{}
    }]

    return ( 
        <nav className="navbar" style={{justifyContent: (window.innerWidth<1024?"space-around":"left")}}>
            <ul className="menus">
                {DropdownItems.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel} refresh={refresh}/>;
                    })}
            </ul>
            <ul className="menus">
                <span className="stadi" style={{marginTop:"8px", marginLeft:"8px", fontSize: (window.innerWidth>430?"18px":String(18*window.innerWidth/430)+"px")}}>Stadi</span>
                <span className="verse" style={{marginTop:"8px", marginRight:"8px", fontSize: (window.innerWidth>430?"18px":String(18*window.innerWidth/430)+"px")}}>verse</span>
            </ul>
            <ul className="menus">
                {userObject.hasOwnProperty('username')?
                LoggedInDropdown.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
                    })
                :
                LoginDropdown.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
                    })
                }
            </ul>
            {userObject.hasOwnProperty('username')?"":
            <ul className="menus">
                {ConnectWallet.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
                    })}
            </ul>
            }
        </nav>
     );
}
 
export default Navbar;