import { useEffect, useState } from "react";
import '../../manage.css';
import EntityPopup from './EntityMods/EntityPopup';

const Team = ({userObject, setUserObject}) => {
    //nameKey = the type of entities parsed
    const nameKey = "sailing";
    // costs (for display only)
    const teamCost = 2000;
    const entityCost = 100;
    const [team, setTeam] = useState(sessionStorage.getItem(nameKey + "Team") ? JSON.parse(sessionStorage.getItem(nameKey + "Team")) : undefined);
    const [teamLoader, setTeamLoader] = useState(true);
    const [teamError, setTeamError] = useState(undefined);
    const [entities, setEntities] = useState(sessionStorage.getItem(nameKey + "Entities") ? JSON.parse(sessionStorage.getItem(nameKey + "Entities")) : undefined);
    const [entityError, setEntityError] = useState(undefined);
    const [entityPopup, setEntityPopup] = useState(undefined);
    const [entityPresets, setEntityPresets] = useState(undefined);
    const [entityInfoToggle, setEntityInfoToggle] = useState([]);
    const [loadingIcon, setLoadingIcon] = useState(false);
    const serverURL=process.env.REACT_APP_SERVER_URL;

    const countries = ["Italy","Spain","Norway","USA","Netherlands","Greece","Germany","France","Finland","Australia","South Africa","Switzerland","Estonia","Croatia","Argentina","Portugal","Brazil","Canada","Bulgaria","Lithuania","Korea","Poland","Austria","Japan","Romania","Sweden","Israel","Hong Kong","Hungary","Turkey","Great Britain","Slovenia","Ecuador","Latvia","Peru","Cyprus","Malta","Denmark","Mexico"];
    const sailingEntities = ["Sailboat"];
    countries.sort();

    async function updateUINFO(id){
        const res = await fetch(serverURL+'api/users/UinfoRefresh/'+id, {
            method:'GET'
            })
            .catch(err=>console.log("Unable to complete request: " + err));
        const data = await res.json();
        setUserObject(data);
    }

    function search(nameKey, entities){
        let sportEntities = []
        for (var i=0; i < entities.length; i++) {
            if (entities[i].type === nameKey){
                sportEntities.push(entities[i]);
            }
        }
        if (sportEntities.length > 0){
            return(sportEntities);
        } else return undefined;
    }

    useEffect(()=>{
        async function getTeam(id){
            const res = await fetch(serverURL+'api/users/teamInfo/'+id, {
                method:'GET'
                })
                .catch(err=>setTeamError("Unable to complete request at this time"));
            const data = await res.json();
            if (!data.error){
                setTeam(data);
            } else {setTeamError(data.error);setTeamLoader(false)}
        }
        async function getEntities(entityIDs) {
            const res = await fetch(serverURL + 'api/users/multipleEntityInfo', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(entityIDs)
            })
                .catch(err => console.log("Unable to complete request: " + err));
            const data = await res.json();
            setEntities(data);
        }
        let teamInit=search(nameKey, userObject.teams);
        if (typeof(teamInit)!=="undefined"){
            if (sessionStorage.getItem(nameKey + "Team")){
                setTeam(JSON.parse(sessionStorage.getItem(nameKey + "Team")));
            } else{
                getTeam(teamInit[0]._id);
            }
        } else{
        setTeamLoader(false);
        };
        let entityInit=search(nameKey, userObject.entities);
        if (typeof(entityInit)!=="undefined"){
            const entityIDs = [];
            for (var i=0; i < entityInit.length;i++){
                entityIDs.push(entityInit[i]._id);
            }
            getEntities(entityIDs);
        } else {setEntities([])}
    },[userObject,serverURL])

    function handleTeamCreate(e){
        e.preventDefault();
        setTeamError(undefined);
        setTeamLoader(true);
        const base=e.target.elements;
        const name = base.teamName.value;
        const country = base.country.value;
        const userID = userObject._id;
        let errorCheck = false;
        //Team name frontend checks
        if(RegExp("[^A-z|0-9_ ]").test(name)){
            errorCheck="Team name may only contain letters, numbers, spaces, or underscores";
        }
        
        let submission = {
            'name': name,
            'type': nameKey,
            'country': country,
            '_id':userID
        }
        async function fetchFunction(){
            const res = await fetch(serverURL+'api/users/createTeam', {
                method:'PUT',
                headers:{'Content-Type':'application/json',},
                body: JSON.stringify(submission)
                })
                .catch(err=>console.log("Unable to complete request: " + err));
            const data = await res.json();
            if (!data.error){
                setTeam(data);
                updateUINFO(userID);
            } else {setTeamError(data.error);setTeamLoader(false)}
            
        }

        if(errorCheck){
            setTeamError(errorCheck);
            setTeamLoader(false);
        } else {
            setTeamError(undefined);
            fetchFunction();
        }
    }

    function handleSailboatCreate(formSubmission, errorCheck){
        //submission contains the following in the form of an Object:
        // name: String
        // role: String

        //errorCheck is either false meaning no errors found, or contains a string with errors
        setEntityError(undefined);
        setLoadingIcon(true);
        const userID = userObject._id;
        //Entity name frontend checks
        let submission = JSON.parse(JSON.stringify(formSubmission));
        
        submission['_id']=userID;
        submission['type'] = nameKey;
        async function fetchFunction() {
            const res = await fetch(serverURL + 'api/users/createSailboat', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(submission)
            })
                .catch(err => {
                    console.log("Unable to complete request: " + err);
                    setEntityError("Ooops! Unable to connect to servers; please check internet connection");
                });
            const data = await res.json();
            if (!data.error) {
                setEntityPopup(undefined);
                setTimeout(async()=> updateUINFO(userID),100);
                setLoadingIcon(false);
            } else {setEntityError(data.error)}
        }
        if(errorCheck){
            setEntityError(errorCheck);
            setLoadingIcon(false);
        } else {
            setEntityError(undefined);
            fetchFunction();
        }
    }

    function handleSailboatEdit(formSubmission,errorCheck){
        //submission contains the following in the form of an Object:
        // name: String
        // role: String
        // attributes: Object

        //errorCheck is either false meaning no errors found, or contains a string with errors
        setEntityError(undefined);
        const entityID = entityPresets._id;
        const userID = userObject._id;
        //Entity name frontend checks
        let submission = JSON.parse(JSON.stringify(formSubmission));
        submission['entity_id']=entityID;
        submission['user_id']=userID;
        async function fetchFunction() {
            const res = await fetch(serverURL + 'api/users/editSailboat', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(submission)
            })
                .catch(err => {
                    console.log("Unable to complete request: " + err);
                    setEntityError("Ooops! Unable to connect to servers; please check internet connection");
                });
            const data = await res.json();
            if (!data.error) {
                setTimeout(async()=> updateUINFO(userID),100);
                setEntityPopup(undefined);
            } else {setEntityError(data.error)}
        }
        if(errorCheck){
            setEntityError(errorCheck);
        } else {
            setEntityError(undefined);
            fetchFunction();
        }
    }

    if (userObject.hasOwnProperty('username')){
        return (
            <div className="manageTeamBody">
                {team ?
                <div className="teamBanner">
                    <div className="teamBannerInfo">
                        <div className="teamName">{(team.name.length>40?team.name.substring(0, 39).toUpperCase()+"..":team.name.toUpperCase())}</div>
                    </div>
                    <div className="teamBannerInfo">
                        <div className="teamInfo">Total Revenue Earned: {team.revenue}</div>
                        <div className="teamInfo">First place finishes: {team.placement.filter(x => x < 2).length}</div>
                        <div className="teamInfo">Top 10 finishes: {team.placement.filter(x => x <= 10).length}</div>
                        <div className="teamInfo">Races Entered: {team.placement.length}</div>
                    </div>
                </div>
                :
                (teamLoader?
                <div className="spinnerDiv">
                    <svg className="spinner2" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path2" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
                :
                <div className="teamForm">
                    <div className="teamFormDescription">
                        <div className="teamFormText">Create a racing team to compete!</div>
                        {teamError ? <div className="teamErrorText">{teamError}</div> : ""}
                        <form className="teamFormForm" onSubmit={handleTeamCreate}>
                            <div className="teamFormItem"><input className="textInput" placeholder="Team Name" required="required" type="text" name="teamName"></input></div>
                            <div className="teamFormItem">
                                <select className="dropdownSelect" required="required" name="country">
                                {countries.map(entity => {
                                    return (<option key={entity} value={entity}>{entity}</option>)
                                })}
                                </select>
                            </div>
                            <div className="teamCost">Cost: {teamCost} tokens</div>
                            <div className="teamFormButton"><input className="submitButton" type="submit" value="Create Team"></input></div>
                        </form>
                    </div>
                    
                </div>
                )}
                {team?
                <div className="entitiesBody">
                    <div className="entitiesHeading">Workshop</div>
                    <div className="entitiesList">Sailboats Owned</div>
                    <div className="entitiesList">Available Races</div>
                    <div className="entitiesList">Purchase</div>
                    <div className="ownedEntitiesList">
                        {entities!==undefined ? entities.map(entity=>{
                            return(
                            <div className="ownedEntityInstance" key={entity._id} onClick={()=>
                                {(entityInfoToggle.some(x => x === entity._id))
                                    ?setEntityInfoToggle(arr => arr.filter((item) => item !== entity._id))
                                    :setEntityInfoToggle(arr => [entity._id])
                                }}>
                                <div className="ownedEntityHeader">
                                    <div>{(entity.name.length>17?entity.name.substring(0,17)+"..":entity.name)}</div>
                                </div>
                                {/* <div className="ownedEntityHeader">{entity.role}</div> */}
                                {entityInfoToggle.some(x => x === entity._id)&&
                                <div className="ownedEntityBody">
                                    {Object.keys(entity.attributes).map(att => {
                                        return(<div key={entity._id + " "+att}>{att}: {entity.attributes[att]}</div>)
                                    })}
                                    <button className="submitConfig" onClick={()=>{setEntityPresets(entity);setEntityPopup(entity.role)}}>EDIT</button>
                                    {entityError?
                                    ""
                                    :<div>{entityError}</div>}
                                </div>
                                }
                            </div>
                            );
                        }):
                        <div className="ownedEntityInstance">
                            Loading Entities. Please wait...
                        </div>
                        }
                    </div>
                    <div className="activeEntitiesList">
                        
                    </div>
                    <div className="createEntitiesList">
                        {sailingEntities.map(entity=>{
                            return(
                            <div key={entity} className="createEntityInstance">
                                <div>{entity}:</div>
                                <div>
                                    {loadingIcon?
                                    <svg className='spinnerLocal active' width="15px" height="15px" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="16.5" cy="16.5" r="15"></circle>
                                    </svg>
                                    :
                                    <button className="submitConfig" onClick={()=>{handleSailboatCreate({'name': Math.random().toString(16).substr(2, 12), 'role': 'Sailboat'})}}>BUY</button>
                                    }
                                </div>
                            </div>
                            );
                        })}
                    </div>
                    {entityPopup?
                    <EntityPopup nameKey={nameKey} entityCost={entityCost} item={entityPopup} closePopup={()=>setEntityPopup(undefined)} reason={entityPresets?"modify":"create"} presets={entityPresets?entityPresets:undefined} handleSailboatEdit={handleSailboatEdit}/>
                    :
                    ""
                    }
                </div>
                :
                ""
                }
                
            </div>
        )
    } else {
        return (
            <div className="text">Whoops! Something went wrong. Please reload the page</div>
        );
    }
}
    

export default Team;