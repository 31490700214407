export const DropdownItems = [
  {
    title: "Services",
    submenu: [
      {
        title: "Sailing",
        link: "/Sailing"
      },
      {
        title: "Football",
        submenu: [
          {
            title: "Fantasy Draft Aid",
            link: "/FantasyDraftHelper"
          },
        ]
      },
      {
        title: "About",
        submenu: [
          {
            title: "Sailing",
            link: "/Info/Sailing"
          },
          // {
          //   title: "Stadiverse",
          //   link: "/About"
          // },
        ]
      },
      {
        title: "Home",
        link: "/"
      },
    ]
  },
]