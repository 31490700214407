import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import GlobeWindLayer from "./Free/EntityMods/GlobeWindLayer";

const SailingInfo = () => {
    const hour = new Date().getUTCHours();
    const month = new Date().getMonth();
    const sunPosition = [5*Math.cos(Math.PI*(hour-5)/12),(month-6)/6,5*Math.sin(Math.PI*(hour-5)/12)];
    return (
        <div>
            <div>
Welcome to Stadiverse, where sailing enthusiasts and aspiring boat designers come together to embark on an exhilarating journey of creativity, competition, and adventure.
But the excitement doesn't stop there. Once your design is perfected, dive into the heart-pounding world of offshore racing with our cutting-edge simulation technology. Harnessing real-time weather data from around the globe, our platform allows you to test your skills against fellow sailors in thrilling races spanning the vast expanse of the world's oceans. Feel the adrenaline rush as you navigate challenging conditions, strategize your course, and outmaneuver the competition on your quest for victory.
At Stadiverse, we're not just about building boats and racing. We're about fostering a community of like-minded individuals who share a love for the sea and a spirit of adventure. Connect with fellow sailors, exchange tips and tricks, and forge lifelong friendships as you embark on epic virtual voyages together.
Whether you're a seasoned sailor seeking new challenges or a curious newcomer eager to explore the world of sailing, Stadiverse is your ultimate destination. Join us today and set sail on an unforgettable journey of discovery, camaraderie, and boundless excitement.
            </div>
            <div>
            At Stadiverse, we empower individuals to unleash their passion for sailing by offering a unique platform to design and customize their very own sailboat creations. Whether you're an experienced sailor or a novice dreaming of setting sail, our intuitive design tools provide the canvas for you to bring your vision to life. From sleek racing yachts to sturdy cruisers, the possibilities are endless as you craft the vessel of your dreams.
            </div>
            <Canvas shadows camera={{ fov: 30, near: 0.1, far: 1000, position: [0, 1, 5] }}>
                <Suspense fallback={null}>
                    <ambientLight intensity={0.4} />
                    <directionalLight
                        intensity={1}
                        position={sunPosition}
                        lookAt={[0, 0, 0]}
                        color="#ffffff"
                    />
                    <GlobeWindLayer />
                </Suspense>
            </Canvas>
        </div>
     );
}

export default SailingInfo;