import { useState } from "react";
import '../manage.css';
import LoginRegister from "../LoginRegister/LoginRegister";
import Race from "./Free/Race";
import Bet from "./Free/Bet";
import Team from "./Free/Team";

function LoginMenu({userObject, setUserObject}){
        return(
            <LoginRegister userObject={userObject} setUserObject={setUserObject}/>
        );
};

const Sailing = ({userObject, setUserObject}) => {
    const [display, setDisplay] = useState(userObject.hasOwnProperty('username')?'Race':'LoginMenu');
    const [navbar, setNavbar] = useState(userObject.hasOwnProperty('username')?'Race':'LoginMenu');

    return ( 
        <div className="manage">
            <div className="free-navbar">
                {userObject.hasOwnProperty('username')?
                <div className="dash-header">Sailing</div>
                :""}
                {userObject.hasOwnProperty('username')?
                <ul className="free-menus">
                    <li className="free-menu-items"><button className={`manage-navbutton ${navbar==='Race' ? 'active' : ''}`} onClick={()=>{setDisplay('Race');setNavbar('Race')}}>Race</button></li>
                    <li className="free-menu-items"><button className={`manage-navbutton ${navbar==='Bet' ? 'active' : ''}`} onClick={()=>{setDisplay('Bet');setNavbar('Bet')}}>Bet</button></li>
                    <li className="free-menu-items"><button className={`manage-navbutton ${navbar==='Team' ? 'active' : ''}`} onClick={()=>{setDisplay('Team');setNavbar('Team')}}>Manage</button></li>
                </ul>
                :
                <ul className="free-menus">
                    <li className="free-menu-items"><button className={`manage-navbutton ${navbar==='UserMenu' ? 'active':''}`} onClick={()=>{setDisplay('LoginMenu');setNavbar('LoginMenu')}}>{'Login'}</button></li>
                </ul>
                }
            </div>
            {display==='Team' && <Team userObject={userObject} setUserObject={setUserObject}/>}
            {display==='Race' && <Race userObject={userObject} setUserObject={setUserObject}/>}
            {display==='Bet' && <Bet userObject={userObject} setUserObject={setUserObject}/>}
            {display==='LoginMenu' && <LoginMenu userObject={userObject} setUserObject={setUserObject}/>}
        </div>
     );
}
 
export default Sailing;