const Bet = () => {
    return ( 
        <div className="manage-body">
            <div className="manage-team">
                <div className="text">Page Currently in Work</div>
                <div className="text"></div>
                <div className="text"></div>
            </div>
        </div>
     );
}
 
export default Bet;