import SailboatForm from "./SailboatForm";

const EntityPopup = ({item,reason,entityCost,nameKey, closePopup, handleSailboatEdit, presets}) => {
    return (
        <div className="popup-container">
            <div className="popup-body">
                <div className="popupHeader">
                    <div className="popupHeaderDiv">{reason.toUpperCase()} {item.toUpperCase()}</div>
                    <button className="closeButton" onClick={()=>closePopup()}>CLOSE</button>
                </div>
                {{
                    "Sailboat":<SailboatForm nameKey={nameKey} entityCost={entityCost} handleSailboatEdit={handleSailboatEdit} closePopup={closePopup} item={item} reason={reason} presets={presets}/>,
                }[item]}
            </div>
        </div>
    );
}

export default EntityPopup;