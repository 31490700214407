import './FantasyDraftHelper.css'
import playerList from '../../../img/fantasyDraftPlayers.json';
import { useEffect, useState } from "react";
import cleaningFunction from './DraftFunctions/InitialDataFunction.js'


const FantasyDraftHelper = (props) => {
    const teamOptions = [6,8,10,12,14,16,18]
    const draftTypeOptions = ["Snake", "Linear", "Auction"]
    const sortOptions = ["","RB", "WR", "TE", "QB", "K", "DST"]
    const orderOptions = ["VBD","FPTS"]
    const rbMult = 5.25; const wrMult = 5.25; const teMult = 1.5; const qbMult = 2; const kMult = 1; const dstMult = 1;

    const [numberOfTeams, setNumberOfTeams] = useState(6);
    const [draftType, setDraftType] = useState("Snake");
    const [sortSelected, setSortSelected] = useState(sortOptions[0]);
    const [orderSelected, setOrderSelected] = useState(orderOptions[0]);
    const [rbct, setRBct] = useState(2);
    const [wrct, setWRct] = useState(2);
    const [tect, setTEct] = useState(1);
    const [qbct, setQBct] = useState(1);
    const [kct, setKct] = useState(1);
    const [dstct, setDSTct] = useState(1);
    const [flexct, setFLEXct] = useState(1);
    
    const [cleanedPlayerList, setCleanedPlayerList] = useState(cleaningFunction(playerList))
    const [finalPlayerList, setFinalPlayerList] = useState(cleanedPlayerList.map(player=>({...player,VBD:1})));

    const [rbWaiverPts, setRbWaiverPts] = useState()
    const [wrWaiverPts, setWrWaiverPts] = useState()
    const [teWaiverPts, setTeWaiverPts] = useState()
    const [qbWaiverPts, setQbWaiverPts] = useState()
    const [kWaiverPts, setkWaiverPts] = useState()
    const [dstWaiverPts, setDstWaiverPts] = useState()

    const calculateWaiverPts = (playerList,cleanedPlayerList, position, numberOfTeams, PosMult) => {
        const val1 = playerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(numberOfTeams*PosMult)].FPTS
        const val2 = playerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(numberOfTeams*PosMult/2)].FPTS
        const val3 = cleanedPlayerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(numberOfTeams)].FPTS
        const val4 = cleanedPlayerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(numberOfTeams/2)].FPTS
        const val5 = cleanedPlayerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(1)].FPTS
        const valCurrent = cleanedPlayerList.filter((player) => player.Position === position).sort((a, b) => b.FPTS - a.FPTS)[Math.floor(0)].FPTS
        const output = val1*Math.min(val4/valCurrent)
        return output;
    }

    useEffect(()=>{
        setRbWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "RB", numberOfTeams, rbMult));
        setWrWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "WR", numberOfTeams, wrMult));
        setTeWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "TE", numberOfTeams, teMult));
        setQbWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "QB", numberOfTeams, qbMult));
        setkWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "K", numberOfTeams, kMult));
        setDstWaiverPts(calculateWaiverPts(playerList, cleanedPlayerList, "DST", numberOfTeams, dstMult));
    },[numberOfTeams, cleanedPlayerList])

    useEffect(()=>{
        const updatedPlayers = cleanedPlayerList.map(player=>{
        let VBD = 1;
        switch(player.Position){
            case 'RB':
                VBD = (player.FPTS-rbWaiverPts)*(player.FPTS/rbWaiverPts)
                break;
            case 'WR':
                VBD = (player.FPTS-wrWaiverPts)*(player.FPTS/wrWaiverPts)
                break;
            case 'TE':
                VBD = (player.FPTS-teWaiverPts)*(player.FPTS/teWaiverPts)
                break;
            case 'QB':
                VBD = (player.FPTS-qbWaiverPts)*(player.FPTS/qbWaiverPts)
                break;
            case 'K':
                VBD = (player.FPTS-kWaiverPts)*(player.FPTS/kWaiverPts)
                break;
            case 'DST':
                VBD = (player.FPTS-dstWaiverPts)*(player.FPTS/dstWaiverPts)
                break;
            default:
                VBD = 1
                break;
        }
        return {...player,VBD:Math.round(VBD)}
    })
        setFinalPlayerList(updatedPlayers)
    },[cleanedPlayerList, dstWaiverPts, kWaiverPts, qbWaiverPts, rbWaiverPts, teWaiverPts,wrWaiverPts])

    const handleTeamNumberChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the dropdown
        setNumberOfTeams(selectedValue); // Update the state with the selected value
    };
    const handleDraftTypeChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the dropdown
        setDraftType(selectedValue); // Update the state with the selected value
    };
    const handleSortTypeChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the dropdown
        setSortSelected(selectedValue); // Update the state with the selected value
    };
    const handleOrderChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the dropdown
        setOrderSelected(selectedValue); // Update the state with the selected value
    }

    return ( 
        <div className="fantasyDraft">
            <div className="fantasyDraft-top">
                <div className="heading"><span className="stadi">Draft</span><span className="verse">Helper</span></div>
            </div>
            <div>League Size: <span> </span>
                <select value={numberOfTeams} onChange={handleTeamNumberChange}>
                    {teamOptions.map((number) => (
                        <option key={number} value={number}>
                        {number}
                        </option>
                    ))}
                </select>
            </div>
            <div>Draft Type: <span> </span>
                <select value={draftType} onChange={handleDraftTypeChange}>
                    {draftTypeOptions.map((val) => (
                        <option key={val} value={val}>
                        {val}
                        </option>
                    ))}
                </select>
            </div>
            <div>Sort: <span> </span>
                <select value={sortSelected} onChange={handleSortTypeChange}>
                    {sortOptions.map((val) => (
                        <option key={val} value={val}>
                        {val}
                        </option>
                    ))}
                </select>
            </div>
            <div>Order: <span> </span>
                <select value={orderSelected} onChange={handleOrderChange}>
                    {orderOptions.map((val) => (
                        <option key={val} value={val}>
                        {val}
                        </option>
                    ))}
                </select>
            </div>
            <table className='remainingPlayers'>
                <thead>
                    <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Projected FPTS <button onClick={()=>setOrderSelected("FPTS")}>^</button></th>
                    <th>VBD Score <button onClick={()=>setOrderSelected("VBD")}>^</button></th>
                    <th>Position Rank</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    sortSelected?finalPlayerList.filter((player) => player.Position === sortSelected).sort((a, b) => b[orderSelected] - a[orderSelected]).map(player => 
                    (
                    <tr key={player.Name}>
                        <td>{player.Name}</td>
                        <td>{player.Position}</td>
                        <td>{Math.round(player.FPTS)}</td>
                        <td>{player.VBD}</td>
                        <td>{player.positionRank}</td>

                    </tr>
                    )):
                    finalPlayerList.sort((a, b) => b[orderSelected] - a[orderSelected]).map(player =>(
                    <tr key={player.Name}>
                        <td>{player.Name}</td>
                        <td>{player.Position}</td>
                        <td>{Math.round(player.FPTS)}</td>
                        <td>{player.VBD}</td>
                        <td>{player.positionRank}</td>
                    </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
     );
}
 
export default FantasyDraftHelper;