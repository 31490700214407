export const Stops = [
        {"city":"Miami",
        "country":"USA",
        "continent":"North America",
        "sea":"Caribbean",
        "latitude":25.703660,
        "longitude":-80.053379
        }
        ,
        {"city":"Tampa",
        "country":"USA",
        "continent":"North America",
        "sea":"Caribbean",
        "latitude":27.526906,
        "longitude":-82.823555
        }
        ,
        {"city":"Houston",
        "country":"USA",
        "continent":"North America",
        "sea":"Caribbean",
        "latitude":29.332861,
        "longitude":-94.635301
        }
        ,
        {"city":"Corpus Christi",
        "country":"USA",
        "continent":"North America",
        "sea":"Caribbean",
        "latitude":27.696173,
        "longitude":-97.036716
        }
        ,
        {"city":"Tampico",
        "country":"Mexico",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":22.264951,
        "longitude":-97.720409
        }
        ,
        {"city":"Veracruz",
        "country":"Mexico",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":19.224481,
        "longitude":-96.101176
        }
        ,
        {"city":"Coatzacoalcos",
        "country":"Mexico",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.168753,
        "longitude":-94.449086
        }
        ,
        {"city":"Cancun",
        "country":"Mexico",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":21.221413,
        "longitude":-86.692406
        }
        ,
        {"city":"San Pedro",
        "country":"Belize",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":17.917917,
        "longitude":-87.947946
        }
        ,
        {"city":"Belize City",
        "country":"Belize",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":17.491561,
        "longitude":-88.034608
        }
        ,
        {"city":"Puerto Cortes",
        "country":"Honduras",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":15.865812,
        "longitude":-87.962113
        }
        ,
        {"city":"La Ceiba",
        "country":"Honduras",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":15.801355,
        "longitude":-86.794501
        }
        ,
        {"city":"Roatan",
        "country":"Honduras",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":16.304043,
        "longitude":-86.534884
        }
        ,
        {"city":"Puerto Lempira",
        "country":"Honduras",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":15.409797,
        "longitude":-83.719706
        }
        ,
        {"city":"Puerto Cabezas (Bilwi)",
        "country":"Nicaragua",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":14.010161,
        "longitude":-83.367022
        }
        ,
        {"city":"Bluefields",
        "country":"Nicaragua",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":11.971788,
        "longitude":-83.696951
        }
        ,
        {"city":"San Andres",
        "country":"Colombia",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":12.592780,
        "longitude":-81.676865
        }
        ,
        {"city":"Limon",
        "country":"Costa Rica",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":10.005503,
        "longitude":-83.008877
        }
        ,
        {"city":"Colon",
        "country":"Panama",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":9.387081,
        "longitude":-79.919898
        }
        ,
        {"city":"Turbo",
        "country":"Colombia",
        "continent":"South America",
        "sea":"Caribbean",
        "latitude":8.088769,
        "longitude":-76.759511
        }
        ,
        {"city":"Cartagena de Indias",
        "country":"Colombia",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":8.088769,
        "longitude":-76.759511
        }
        ,
        {"city":"Nassau",
        "country":"Bahamas",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":25.113332,
        "longitude":-77.329347
        }
        ,
        {"city":"Turks and Caicos Islands",
        "country":"British Territory",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":21.570610,
        "longitude":-71.788326
        }
        ,
        {"city":"La Habana",
        "country":"Cuba",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":23.167634,
        "longitude":-82.409572
        }
        ,
        {"city":"George Town",
        "country":"Cayman Islands",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":19.264221,
        "longitude":-81.419885
        }
        ,
        {"city":"Kingston",
        "country":"Jamaica",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":17.900342,
        "longitude":-76.784271
        }
        ,
        {"city":"Santiago de Cuba",
        "country":"Cuba",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":19.954633,
        "longitude":-75.864938
        }
        ,
        {"city":"Santo Domingo",
        "country":"Republica Dominicana",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.402485,
        "longitude":-69.908598
        }
        ,
        {"city":"Port-au-Prince",
        "country":"Haiti",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.573883,
        "longitude":-72.384484
        }
        ,
        {"city":"San Juan",
        "country":"Puerto Rico",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.472836,
        "longitude":-66.092358
        }
        ,
        {"city":"Charlotte Amalie",
        "country":"US Virgin Islands",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.306520,
        "longitude":-64.929727
        }
        ,
        {"city":"Road Town",
        "country":"British Virgin Islands",
        "continent":"Central America",
        "sea":"Caribbean",
        "latitude":18.407795,
        "longitude":-64.603779
        }
    ]