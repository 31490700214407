import { useRef, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { useTexture, OrbitControls } from "@react-three/drei";
import EarthDayMap from "../../../../../img/8k_earth_daymap.jpg";
// import GlobeNormalMap from "../../../../../img/8k_earth_normal_map.jpg";
// import GlobeSpecularMap from "../../../../../img/8k_earth_specular_map.jpg";
import * as THREE from "three";
// import GlobeSnowLayer from "./GlobeSnowLayer";
// import GlobeCloudLayer from "./GlobeCloudLayer";

import {Stops} from "./coastal_cities";

function LatLongtoCartesian(input) {
    //expects {lat:val , long:val}
    const {lat , lon, rad} = input;
    const y = rad * Math.sin((lat * Math.PI/180));
    const x = rad * Math.sin(lon* Math.PI/180) * Math.cos(lat* Math.PI/180);
    const z = rad * Math.cos(lon* Math.PI/180) * Math.cos(lat* Math.PI/180);
    return({x,y,z})
}
function CartesiantoLatLong(input) {
    //expects {x:val , y:val , z:val}
    const {x,y,z} = input;
    let lon = Math.atan2(x, z)* (180 / Math.PI);
    const lat = Math.asin(y, Math.sqrt(x * x + z * z))  * (180 / Math.PI);
    return ({lat:lat,lon:lon})
}

const Sphere = ({selectedPoint, setSelectedPoint}) => {
    const meshRef = useRef();
    const orbitControlsRef = useRef(null);
    const [zoomDistance, setZoomDistance] = useState(5);
    const [rotateSpeed, setRotateSpeed] = useState(0.0001);
    const [zoomSpeed, setZoomSpeed] = useState(0.0001);
    const [indicatorSize,setIndicatorSize] = useState(0.0001);
    const [largeIndicatorSize,setLargeIndicatorSize] = useState(0.0001)
    const [generatePos, setGeneratePos] = useState(false);
    const [racePoints, setRacePoints] = useState([{_id:"aosndoasd",stops:Stops}]);

    useEffect(()=>{
        setRotateSpeed(zoomDistance**1.75*0.02);
        setIndicatorSize(zoomDistance**1.5*0.001);
        setZoomSpeed(zoomDistance*0.25);
        setLargeIndicatorSize(zoomDistance**1.5*0.0015);
        setGeneratePos(false);
    },[zoomDistance, selectedPoint])

    useFrame((state)=>{
        if (orbitControlsRef.current.getDistance()!==zoomDistance){
            setZoomDistance(orbitControlsRef.current.getDistance())
        }
        if (generatePos){
            setGeneratePos(false);
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(state.mouse, state.camera);
            const intersects = raycaster.intersectObject(state.scene.getObjectByName("Globe"));
            if (intersects.length > 0) {
                const {x,y,z} = intersects[0].point;
                const {lat,lon} = CartesiantoLatLong({x,y,z});
                setSelectedPoint({"latitude":lat,"longitude":lon});
            }
        }
    })

    const       [dayMap] = 
    useTexture( [EarthDayMap] );

    return(
        <>
            <group ref={meshRef}>
                <mesh name="Globe" rotation={[0,Math.PI*3/2,0]} position={[0,0,0]} onClick={()=>{setGeneratePos(true)}}>
                    <sphereGeometry args={[1,360,180]}/>
                    {/* <meshPhongMaterial specularMap={specularMap}/> */}
                    {/* <meshStandardMaterial map={dayMap} normalMap={normalMap} metalness={0.3} roughness={0.6}/> */}
                    <meshStandardMaterial map={dayMap} metalness={0.3} roughness={0.6}/>
                </mesh>
                <OrbitControls ref={orbitControlsRef}
                    enableZoom={true}
                    enablePan={false}
                    enableRotate={true}
                    zoomSpeed={zoomSpeed}
                    rotateSpeed={rotateSpeed}
                    minDistance={1.2}
                    maxDistance={8}
                />
                {/* <GlobeSnowLayer/> */}
                {/* <GlobeCloudLayer/> */}
                <mesh name="clickPoint" position={Object.values(LatLongtoCartesian({lat:selectedPoint?.latitude,lon:selectedPoint?.longitude,rad:1}))}>
                    <sphereGeometry args={[indicatorSize,12,12]}/>
                    <meshStandardMaterial attach={"material"} color={0xff0000}/>
                </mesh>
                {racePoints[0]['stops'].map(point => {
                    return(
                    <mesh onPointerOver={()=>(document.body.style.cursor = 'pointer')} onPointerOut={()=>(document.body.style.cursor = 'auto')} key={point.city} onClick={()=>{setSelectedPoint(point)}} name="clickPoint" position={Object.values(LatLongtoCartesian({lat:point.latitude,lon:point.longitude,rad:1}))}>
                        <sphereGeometry args={[largeIndicatorSize,12,12]}/>
                        <meshStandardMaterial attach={"material"} color={selectedPoint?.city===point.city?0xffff00:0x00ffff}/>
                    </mesh>
                )})}
            </group>
        </>
    )
}

export default Sphere;