import './freelogin.css';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const LoginRegister = ({userObject, setUserObject}) => {

    const serverURL=process.env.REACT_APP_SERVER_URL;
    const [submitStatus, setSubmitStatus] = useState(false);
    const [registerPage, setRegisterPage] = useState(false);
    const [submitText, setSubmitText] = useState('Log in');
    const [showPassword, setShowPassword] = useState(true);
    const [authStatus, setAuthStatus] = useState(false);
    const [uinfo, setUinfo] = useState();
    const [Perror, setPerror] = useState();
    const [Uerror, setUerror] = useState();
    const [Eerror, setEerror] = useState();
    const navigate = useNavigate();

    useEffect(()=>{
        if (userObject.hasOwnProperty('username')){
            navigate("/")
        }
    })

    function handleLogin(e){
        setSubmitStatus(true);
        e.preventDefault();
        setUerror(undefined);
        setPerror(undefined);
        setEerror(undefined);
        setUinfo(undefined);
        setAuthStatus(false);
        const base=e.target.elements;
        const intUsername = base.username.value;
        const intPassword = base.password.value;
        let errorCheck = false;
        //username frontend checks
        let Uerror = undefined;

        //password frontend checks
        let Perror = undefined;
        
        //preprocessing
        const submission={
            'username':intUsername,
            'password':intPassword,
        };
        //Login fetch
        async function fetchFunction(){
            const res = await fetch(serverURL+'api/users/login', {
                method:'POST',
                headers:{'Content-Type':'application/json',},
                body: JSON.stringify(submission)
                })
                .catch(err=>{
                    console.log("Unable to complete request: " + err);
                    setSubmitStatus(false);
                    setAuthStatus(true);
                    setPerror("Servers currently under maintenance; please come back later");
                });
            const data = await res.json();
            if(!data.Uerror && !data.Perror){
                setUinfo(data);
                setTimeout(() => setAuthStatus(true), 500);
                setTimeout(() => {setSubmitStatus(false);setUserObject(data);navigate(-1)}, 1000);
                setUerror(undefined);
                setPerror(undefined);
                setEerror(undefined);
                setAuthStatus(false);
            } else {
                if (data.Uerror){
                    setUerror(data.Uerror);
                }
                if (data.Perror){
                    setPerror(data.Perror);
                }
                setSubmitStatus(false);
            }
        }
        if(errorCheck){
            setUerror(Uerror);
            setPerror(Perror);
            setEerror(Eerror);
            setSubmitStatus(false);
        } else {
            fetchFunction();
        }
    }

    function handleRegister(e){
        e.preventDefault();
        setUerror(undefined);
        setPerror(undefined);
        setEerror(undefined);
        setUinfo(undefined);
        const base=e.target.elements;
        const intUsername = base.username.value;
        const intPassword = base.password.value;
        const intEmail = base.email.value;
        let errorCheck = false;
        //username frontend checks
        let Uerror = undefined;
        if (intUsername.length < 5){
            Uerror = "Username must have at least 5 characters";
            errorCheck = true;
        }
        if (RegExp("[^A-z|0-9_]").test(intUsername)){
            Uerror = "Username accepts only letters, numbers, and underscores";
            errorCheck = true;
        }
        if (!RegExp("[0-9_-]*[a-zA-Z]{3,}[0-9_-]*").test(intUsername)){
            Uerror = "Username must contain at least 3 consecutive letters";
            errorCheck = true;
        }
        if (/\s/.test(intUsername)){
            Uerror = "Username cannot contain spaces"
            errorCheck = true;
        }
        //password frontend checks
        let Perror = undefined;
        if (intPassword.length < 5){
            Perror = "Password must have at least 5 characters";
            errorCheck = true;
        }
        if (/\s/.test(intPassword)){
            Perror = "Password cannot contain spaces"
            errorCheck = true;
        }
        //email frontend checks
        let Eerror = undefined;
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(intEmail)) {
            Eerror = "Please enter a valid email";
            errorCheck = true;
        }
        
        //pre-processing
        let submission={
            'username':intUsername,
            'password':intPassword,
            'email':intEmail,
        };
        
        //passed frontend checks - passing to backend processing
        async function fetchFunction(){
            const res = await fetch(serverURL+'api/users/register', {
                method:'POST',
                headers:{'Content-Type':'application/json',},
                body: JSON.stringify(submission)
                })
                .catch(err=>console.log("Unable to complete request: " + err));
            const data = await res.json();
            if (!data.Uerror && !data.Eerror){
                setUinfo(data);
                setTimeout(() => setAuthStatus(true), 500);
                setTimeout(() => {setSubmitStatus(false);setUserObject(data);navigate(-1)}, 1000);
                setUerror(undefined);
                setPerror(undefined);
                setEerror(undefined);
                setAuthStatus(false);
            } else {
                if (data.Uerror){
                    setUerror(data.Uerror);
                }

                if (data.Eerror){
                    setEerror(data.Eerror);
                }
                setSubmitStatus(false);
            }
        };
        if(errorCheck){
            setUerror(Uerror);
            setPerror(Perror);
            setEerror(Eerror);
            setSubmitStatus(false);
        } else {
            setAuthStatus(false);
            setSubmitStatus(true);
            fetchFunction();
        }
    }

    return(
    <div className="container">
        <div className="container_inner">
            <div className="container_inner__login">
                <div className="login">
                    <div className="login_profile">
                    </div>
                    
                    <div className="login_desc">
                        <h5>
                            Welcome back to
                        </h5>
                        <span className="stadi">Stadi</span><span className="verse">verse</span>
                        
                    </div>
                    <div className="login_inputs">
                        <form className={`form ${submitStatus ? 'hide' : 'active'}`} onSubmit={registerPage ? handleRegister : handleLogin}>
                            <div className='loginErrors'>{Uerror? Uerror : ""}</div>
                            <div className='loginErrors'>{Perror? Perror : ""}</div>
                            <div className='loginErrors'>{Eerror? Eerror : ""}</div>
                            <input className="textInput" placeholder="Username" required="required" type="username" name="username"></input>
                            <div className='passwordDiv'>
                                <input className="textInput" placeholder="Password" required="required" type={showPassword?"password":"text"} name="password"></input>
                                <span className={`showpassword ${submitStatus ? 'hide' : 'active'}`} onClick={()=>setShowPassword(!showPassword)}>&#9681;</span> 
                            </div>
                            {registerPage? 
                            <input className="textInput" placeholder="Email" required="required" type="email" name="email"></input>
                            : 
                            "" 
                            }
                            <input className="submitButton" type="submit" value={submitText}></input>
                        </form>
                        <div className={`forgotten ${submitStatus ? 'hide' : 'active'}`}>
                            <a className="forgottentext" href="#0">Forgot your username/password?</a>
                        </div>
                        <div className={`forgotten ${submitStatus ? 'hide' : 'active'}`}>
                            {registerPage? <span className='newtostadiverse'>Return to </span>:<span className='newtostadiverse'>New to stadiverse? </span>}
                            <a className="registerhere" href="#0" onClick={()=>{setRegisterPage(!registerPage);setPerror(undefined);setUerror(undefined);setEerror(undefined);registerPage?setSubmitText('Log in'):setSubmitText('Register')}}>{registerPage? "Login":"Register Here"}</a>
                        </div>
                    </div>
                    <div className={`pleasewait ${submitStatus ? 'active' : 'hide'}`}>
                        <div className="pleasewaittext">{authStatus ? (submitText)+" successful: welcome " + (uinfo? uinfo.username : "new user") : 'contacting client'}</div>
                    </div>
                    <svg className={`spinner ${submitStatus ? 'active' : 'hide'}`} width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        {authStatus 
                        ? "" 
                        :<circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                        }
                        
                    </svg>
                </div>
            </div>
        </div>
    </div>
    );
}
 
export default LoginRegister;